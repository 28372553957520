<template>
    <div data-scroll :data-scroll-id="name" class="latest-news">
        <div class="header-container">
            <text-wipe id="reputation-header" :header="header" :left="true" />
        </div>
        <news-item :item="item" :index="0" :lightMode="lightMode" />
    </div>
</template>

<script>
import textWipe from "@/components/custom/textWipe";
import NewsItem from "@/components/PageComponents/NewsItem";
import { mapGetters } from "vuex";
import first from "lodash/first";

export default {
    props: {
        data: {
            type: Object,
            default: () => {}
        },
        config: {
            type: Object,
            default: () => {}
        },
        lightMode: {
            type: Boolean,
            default: false
        },
        name: {
            type: String,
            default: ""
        }
    },
    components: {
        NewsItem,
        textWipe
    },
    computed: {
        ...mapGetters({
            device: "resize/geDevice"
        }),
        item() {
            let item = first(this.data?.list);

            return {
                header: item?.title,
                date: item?.date,
                path: item?.cover_image?.[0]?.devices?.[this.device?.type],
                id: item?.id
            };
        },
        header() {
            return this.config.header || {};
        }
    }
};
</script>

<style lang="scss" scoped>
.latest-news /deep/ {
    margin: 144px 225px 124px;

    @media only screen and (max-width: 1650px) and (min-width: 1440px) {
        margin: 144px 165px 119px;
    }
    @media only screen and (max-width: 1439px) and (min-width: 1366px) {
        margin: 128px 128px 119px;
    }
    @media only screen and (max-width: 1365px) and (min-width: 1024px) {
        margin: 89px 47px 119px;
    }
    @media only screen and (max-width: 1023px) and (min-width: 768px) {
        margin: 89px 47px 89px;
    }
    @media only screen and (max-width: 767px) {
        margin: 55px 16px 0;
    }

    .date {
        color: white !important;
    }

    .header-container {
        margin-bottom: 55px;
        @media only screen and (max-width: 1024px) and (min-width: 769px) {
            margin-bottom: 40px;
        }
        @media only screen and (max-width: 768px) {
            margin-bottom: 34px;
        }
    }
}

.latest-news /deep/ {
    .header {
        font-size: 46px;
        @media only screen and (max-width: 1365px) and (min-width: 768px) {
            font-size: 34px;
        }
        @media only screen and (max-width: 767px) {
            font-size: 21px;
        }
    }
}
</style>
